import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
//import { onAuthStateChanged } from 'firebase/auth';
//import { auth } from '../firebase';
//import { signOut } from 'firebase/auth';
import React from 'react';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Hidden from '@mui/material/Hidden';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import axios from 'axios';

import { TextField, Typography, Box } from '@mui/material';

import SalesAndOrders from './SalesAndOrders';
import DigitalPerformance from './DigitalPerformance';
import MenuPerformance from './MenuPerformance';
import CustomerInsight from './CustomerInsight';
import Settings from './Settings';


const Dashboard = () => {
  const location = useLocation();
  const idToken = location.state?.idToken;
  const navigate = useNavigate();
  const [currentSection, setCurrentSection] = useState('salesAndOrders');
  const [mobileOpen, setMobileOpen] = useState(false);
  const [userId, setUserId] = useState(null);

  const drawerWidth = 240;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  // Inside the Dashboard component:
  useEffect(() => {
    //console.log(idToken);
    const checkUser = async () => {
      if (!idToken) {
        navigate('/');
        return;
      }

      try {
        const response = await axios.get(process.env.REACT_APP_API_ADDRESS + '/checkUser', {
          headers: {
            'Authorization': `Bearer ${idToken}`,
          },
        });

        if (response.data && response.data.uid) {
          setUserId(response.data.uid);
          console.log(response.data.uid);
        } else {
          navigate('/');
        }
      } catch (error) {
        console.error('Error checking user:', error);
        navigate('/');
      }
    };

    checkUser();
  }, [navigate, idToken]);


  const handleLogout = async () => {
    try {
      if (userId) {
        await axios.post(process.env.REACT_APP_API_ADDRESS + '/logout', { uid: userId });
        navigate('/');
      }
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };


  const handleSectionChange = (section) => {
    setCurrentSection(section);
  };

  const drawer = (
      <div>
        <Toolbar />
        <Divider />
        <List>
          {['Sales and Orders', 'Customer Insights'/*, 'Operational Metrics'*/, 'Menu Performance', 'Digital Performance', "Settings"].map((text, index) => (
            <ListItem 
              button 
              key={text} 
              onClick={() => setCurrentSection(text.split(' ').map((word, i) => i === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(''))}>
              <ListItemText primary={text} />
            </ListItem>
          ))}
          <ListItem>
            <Button variant="contained" color="primary" onClick={handleLogout}>
              Logout
            </Button>
          </ListItem>
        </List>
      </div>
    );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ marginRight: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }} // Better open performance on mobile.
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, p: 3, pt: 8 }}>
        <Toolbar />
        {currentSection === 'salesAndOrders' && <div id="salesAndOrders"><SalesAndOrders userId={userId} idToken={idToken} /></div>}
        {currentSection === 'customerInsights' && <div id="customerInsights"><CustomerInsight userId={userId} idToken={idToken} /></div>}
        {/*currentSection === 'operationalMetrics' && <div id="operationalMetrics">Operational Metrics</div>*/}
        {currentSection === 'menuPerformance' && <div id="menuPerformance"><MenuPerformance userId={userId} idToken={idToken} /></div>}
        {currentSection === 'digitalPerformance' && <div id="digitalPerformance"><DigitalPerformance userId={userId} idToken={idToken} /></div>}
        {currentSection === 'settings' && <div id="settings"><Settings userId={userId} idToken={idToken} /></div>}
      </Box>

    </Box>
  );
}


export default Dashboard;


/*

    Sales and Orders
        Total sales
        Total orders
        Average order value
        Cancellation and return rate

    Customer Insights
        Customer data
        Feedback and ratings
        Customer acquisition cost

    Operational Metrics
        Average order preparation and delivery time
        Inventory management

    Menu Performance
        Popular menu items
        Discounts and promotions

    Digital Performance
        Website/App analytics
        Payment methods

*/