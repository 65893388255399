import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Button,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Divider,
    FormControlLabel,
    Switch,
    Snackbar,
    Alert,
    Box
} from '@mui/material';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

function Settings({ userId, idToken }) {
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

    const days = ['mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag', 'søndag'];

    const [weeklyHours, setWeeklyHours] = useState(
        days.reduce((acc, day) => ({
            ...acc,
            [day]: {
                isOpen: true,
                intervals: [
                    {
                        open: '00:00',
                        close: '00:00',
                        delivery_open: '00:00',
                        delivery_close: '00:00'
                    }
                ]
            }
        }), {})
    );

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const fetchOpeningHours = async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_ADDRESS + '/fetchOpeningHours', {
                userId,
                idToken
            });

            const fetchedHours = response.data.scheduled_opening_hours;
            const tempClosureDates = response.data.temp_closure_dates;
            if (tempClosureDates) {
                setStartDate(tempClosureDates.startDate);
                setEndDate(tempClosureDates.endDate);
            }
            const formattedHours = {};

            for (let day in fetchedHours) {
                formattedHours[day.toLowerCase()] = {
                    isOpen: fetchedHours[day].isOpen,
                    intervals: fetchedHours[day].intervals.map(interval => ({
                        open: interval.open || 'Closed',
                        close: interval.close || 'Closed',
                        delivery_open: interval.delivery_open || 'Closed',
                        delivery_close: interval.delivery_close || 'Closed'
                    }))
                };
            }

            setWeeklyHours(formattedHours);

        } catch (error) {
            console.error('Error fetching opening hours from server:', error);

            if (error.response && error.response.status === 500 && error.message.includes("expired")) {
                window.location.href = '/login';
            }
        }
    };

    useEffect(() => {
        if (userId) {
            fetchOpeningHours();
        }
    }, [userId]);

    useEffect(() => {
        console.log(weeklyHours);
    }, [weeklyHours]);

    const handleHoursChange = (day, intervalIndex, period, newValue) => {
        const formattedValue = newValue ? newValue.format('HH:mm') : '00:00';

        if (period.startsWith('delivery_')) {
            const basePeriod = period.replace('delivery_', '');
            const baseTime = weeklyHours[day].intervals[intervalIndex][basePeriod];

            const newTimeValue = newValue ? newValue.hour() * 60 + newValue.minute() : 0;
            const baseTimeValue = dayjs(baseTime, 'HH:mm').hour() * 60 + dayjs(baseTime, 'HH:mm').minute();

            if ((period === 'delivery_open' && newTimeValue < baseTimeValue) ||
                (period === 'delivery_close' && newTimeValue > baseTimeValue)) {
                setSnackbar({
                    open: true,
                    message: 'Delivery time must be within the opening hours',
                    severity: 'error'
                });
                return;
            }
        }

        setWeeklyHours(prevHours => ({
            ...prevHours,
            [day]: {
                ...prevHours[day],
                intervals: prevHours[day].intervals.map((interval, index) =>
                    index === intervalIndex ? { ...interval, [period]: formattedValue } : interval
                )
            }
        }));
    };

    const addInterval = (day) => {
        setWeeklyHours(prevHours => ({
            ...prevHours,
            [day]: {
                ...prevHours[day],
                intervals: [...prevHours[day].intervals, { open: '00:00', close: '00:00', delivery_open: '00:00', delivery_close: '00:00' }]
            }
        }));
    };

    const removeInterval = (day, intervalIndex) => {
        setWeeklyHours(prevHours => ({
            ...prevHours,
            [day]: {
                ...prevHours[day],
                intervals: prevHours[day].intervals.filter((_, index) => index !== intervalIndex)
            }
        }));
    };

    const handleDayToggle = (day, isOpen) => {
        setWeeklyHours(prevHours => ({
            ...prevHours,
            [day]: {
                ...prevHours[day],
                isOpen: isOpen
            }
        }));
    };

    const handleToggleDelivery = (day, intervalIndex, isDeliveryOpen) => {
        setWeeklyHours(prevHours => ({
            ...prevHours,
            [day]: {
                ...prevHours[day],
                intervals: prevHours[day].intervals.map((interval, index) =>
                    index === intervalIndex ? {
                        ...interval,
                        delivery_open: isDeliveryOpen ? '00:00' : 'Closed',
                        delivery_close: isDeliveryOpen ? '00:00' : 'Closed'
                    } : interval
                )
            }
        }));
    };

    const handleTemporaryClosureSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(process.env.REACT_APP_OPENING_HOURS_API_ADDRESS + `/TempClosed/${userId}`, {
                startDate,
                endDate,
                userId,
                idToken
            });

            if (response.data && response.data.message) {
                alert(response.data.message);
            }
        } catch (error) {
            console.error('Error setting temporary closure:', error);
            alert('Failed to set temporary closure. Please try again.');
        }
    }

    const handleRemoveTemporaryClosure = async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_OPENING_HOURS_API_ADDRESS + `/OpenRes/${userId}`, {
                userId,
                idToken
            });

            if (response.data && response.data.message) {
                alert(response.data.message);
                setStartDate('');
                setEndDate('');
            }
        } catch (error) {
            console.error('Error removing temporary closure:', error);
            alert('Failed to remove temporary closure. Please try again.');
        }
    }

    const saveOpeningHours = async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_ADDRESS + '/saveOpeningHours', {
                userId,
                idToken,
                openingHours: weeklyHours
            });

            if (response.data.success) {
                setSnackbar({ open: true, message: "Opening hours saved successfully!", severity: 'success' });
            } else {
                setSnackbar({ open: true, message: response.data.message, severity: 'error' });
            }
        } catch (error) {
            console.error('Error saving opening hours to server:', error);
            setSnackbar({ open: true, message: "Error saving opening hours to server", severity: 'error' });
            if (error.response && error.response.status === 500 && error.message.includes("expired")) {
                window.location.href = '/login';
            }
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h2>Manage Weekly Opening Hours</h2>
                    {days.map(day => (
                        <Box key={day} mb={2}>
                            <Divider />
                            <Box mt={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <InputLabel>{day}</InputLabel>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={weeklyHours[day]?.isOpen}
                                                    onChange={() => handleDayToggle(day, !weeklyHours[day]?.isOpen)}
                                                />
                                            }
                                            label={weeklyHours[day]?.isOpen ? "Open" : "Closed Day"}
                                        />
                                    </Grid>
                                    {weeklyHours[day].isOpen && weeklyHours[day].intervals.map((interval, index) => (
                                        <Grid container spacing={2} key={index} style={{ marginTop: '10px' }}>
                                            <Grid item xs={6} sm={3}>
                                                <TimeField
                                                    label="Open"
                                                    value={dayjs(interval.open || '00:00', 'HH:mm')}
                                                    onChange={(newValue) => handleHoursChange(day, index, 'open', newValue)}
                                                    format="HH:mm"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <TimeField
                                                    label="Close"
                                                    value={dayjs(interval.close || '00:00', 'HH:mm')}
                                                    onChange={(newValue) => handleHoursChange(day, index, 'close', newValue)}
                                                    format="HH:mm"
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={interval.delivery_open !== "Closed"}
                                                            onChange={() => handleToggleDelivery(day, index, interval.delivery_open === "Closed")}
                                                        />
                                                    }
                                                    label={interval.delivery_open !== "Closed" ? "Delivery Open" : "No Delivery"}
                                                />
                                                <TimeField
                                                    label="Delivery Open"
                                                    value={dayjs(interval.delivery_open || '00:00', 'HH:mm')}
                                                    onChange={(newValue) => handleHoursChange(day, index, 'delivery_open', newValue)}
                                                    format="HH:mm"
                                                    fullWidth
                                                    disabled={interval.delivery_open === "Closed"}
                                                />
                                            </Grid>
                                            <Grid item xs={6} sm={3}>
                                                <TimeField
                                                    label="Delivery Close"
                                                    value={dayjs(interval.delivery_close || '00:00', 'HH:mm')}
                                                    onChange={(newValue) => handleHoursChange(day, index, 'delivery_close', newValue)}
                                                    format="HH:mm"
                                                    fullWidth
                                                    disabled={interval.delivery_open === "Closed"}
                                                />
                                            </Grid>
                                            {weeklyHours[day].intervals.length > 1 && (
                                                <Grid item xs={12} sm={2}>
                                                    <Button onClick={() => removeInterval(day, index)} fullWidth>
                                                        Remove Interval
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Grid>
                                    ))}
                                    {weeklyHours[day].isOpen && (
                                        <Grid item xs={12}>
                                            <Button onClick={() => addInterval(day)} fullWidth>
                                                Add Interval
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </Box>
                        </Box>
                    ))}
                    <Grid item xs={12} style={{ marginTop: '20px' }}>
                        <Button variant="contained" color="primary" onClick={saveOpeningHours} fullWidth>
                            Save Opening Hours
                        </Button>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
                    <h2>Set Temporary Closure</h2>

                    <form onSubmit={handleTemporaryClosureSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="startDate"
                                    label="Start Date"
                                    type="date"
                                    value={startDate}
                                    onChange={e => setStartDate(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="endDate"
                                    label="End Date"
                                    type="date"
                                    value={endDate}
                                    onChange={e => setEndDate(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" variant="contained" color="primary" fullWidth>
                                    Set Closure
                                </Button>
                            </Grid>
                        </Grid>
                    </form>

                    {startDate && endDate ? (
                        <Grid item xs={12} style={{ marginTop: '10px' }}>
                            <Button onClick={handleRemoveTemporaryClosure} variant="contained" color="secondary" fullWidth>
                                Remove Temporary Closure
                            </Button>
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </LocalizationProvider>
    );
}

export default Settings;
