import React, { useEffect, useState } from 'react';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import CustomLineChart from './CustomLineChart';
import CustomBarChart from './CustomBarChart';
//import CustomHeatmap from './CustomHeatmap';
//import { getDatabase, ref, onValue } from 'firebase/database';
import axios from 'axios';
import regression from 'regression';


const CustomerInsight = ({ userId, idToken }) => {
  const [orders, setOrders] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState(10000); // Default interval, e.g., 10000 days

  const fetchOrders = async () => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_ADDRESS + '/fetchOrders', {
        userId,
        selectedInterval,
        idToken
      });
      setOrders(response.data);
    } catch (error) {
      console.error('Error fetching orders from server:', error);

      if (error.response && error.response.status === 500 && error.message.includes("expired")) {
        // Redirect to login page if the token has expired
        window.location.href = '/login';
      }
    
    }
  };


  useEffect(() => {
    if (userId && selectedInterval) {
      fetchOrders();
    }
  }, [userId, selectedInterval]);



  const getAverageOrderValue = () => {
    if (orders.length === 0) {
      return 0;
    }

    const totalRevenue = orders.reduce((total, order) => total + parseFloat(order.totalPrice), 0);
    const averageOrderValue = totalRevenue / orders.length;

    return Math.round(averageOrderValue);
  };


  const AverageOrderValue = () => {
    const averageOrderValue = getAverageOrderValue();

    return (
      <div style={{ textAlign: 'center',width:"12%" }}>
        <h5 style={{margin:0}} >Gennemsnitlige Ordre Pris</h5>
        <h1 style={textStyle} >{averageOrderValue}</h1>
      </div>
    );
  };


  const getTotalOrderCount = () => {
    return orders.length;
  };

  const TotalOrderCount = () => {
    const totalOrderCount = getTotalOrderCount();

    return (
      <div style={{ textAlign: 'center', width: '12%' }}>
        <h5 style={{ margin: 0 }}>Totale mængde af ordre</h5>
        <h1 style={textStyle}>{totalOrderCount}</h1>
      </div>
    );
  };


  const getTotalRevenue = () => {
    return orders.reduce((total, order) => total + parseFloat(order.totalPrice), 0).toFixed(2);
  };

  const TotalRevenue = () => {
    const totalRevenue = getTotalRevenue();

    return (
      <div style={{ textAlign: 'center', width: '35%' }}>
        <h5 style={{ margin: 0 }}>Totale indkomst i denne tidsperiode</h5>
        <h1 style={textStyle}>{totalRevenue}</h1>
      </div>
    );
  };


  const getOrdersPerCustomer = () => {
    const ordersPerCustomer = orders.reduce((acc, order) => {
      const customerEmail = order.email;

      if (!acc[customerEmail]) {
        acc[customerEmail] = 0;
      }

      acc[customerEmail] += 1; // Increment the count of orders for this customer

      return acc;
    }, {});

    // Convert the object to an array of objects for chart compatibility
    let dataForChart = Object.keys(ordersPerCustomer).map(email => ({
      name: email, // use 'name' instead of 'customer'
      orders: ordersPerCustomer[email]
    }));

    // Sort the data from least to most amount of orders
    dataForChart = dataForChart.sort((a, b) => a.orders - b.orders);

    //console.log(dataForChart);
    return dataForChart;
  };


  const getRevenuePerCustomer = () => {
    const revenuePerCustomer = orders.reduce((acc, order) => {
      const customerEmail = order.email;

      if (!acc[customerEmail]) {
        acc[customerEmail] = 0;
      }

      acc[customerEmail] += parseFloat(order.totalPrice); // Add the order's total price to the customer's total revenue

      return acc;
    }, {});

    // Convert the object to an array of objects for chart compatibility
    let dataForChart = Object.keys(revenuePerCustomer).map(email => ({
      name: email, // use 'name' instead of 'customer'
      revenue: revenuePerCustomer[email]
    }));

    // Sort the data from least to most revenue
    dataForChart = dataForChart.sort((a, b) => a.revenue - b.revenue);

    //console.log(dataForChart);
    return dataForChart;
  };


  /*const generateRetentionData = () => {
    const timeIntervals = [
      { name: 'Last Week', time: 7 * 24 * 60 * 60 * 1000 },
      { name: 'Last Month', time: 30 * 24 * 60 * 60 * 1000 },
      { name: 'Last 3 Months', time: 90 * 24 * 60 * 60 * 1000 },
      { name: 'Last 6 Months', time: 180 * 24 * 60 * 60 * 1000 },
    ];

    const retentionData = orders.reduce((acc, order) => {
      const customerEmail = order.email;
      const orderTime = new Date(order.timestamp).getTime();

      if (!acc[customerEmail]) {
        acc[customerEmail] = { name: customerEmail, bins: timeIntervals.map(interval => ({ bin: interval.name, count: 0 })) };
      }

      acc[customerEmail].bins.forEach((bin) => {
        const interval = timeIntervals.find(interval => interval.name === bin.bin);
        if (Date.now() - orderTime <= interval.time) {
          bin.count += 1; // Increment the count of orders for this customer
        }
      });

      return acc;
    }, {});

    // Convert the object to an array of objects for chart compatibility
    const dataForChart = Object.values(retentionData);

    // Define the accessors
    const xAccessor = d => d.name;
    const yAccessor = d => d.bin;
    const binsAccessor = d => d.bins;

    console.log(dataForChart);
    return { data: dataForChart, xAccessor, yAccessor, binsAccessor };
  };

  // Get the data and the accessors
  const { data, xAccessor, yAccessor, binsAccessor } = generateRetentionData();*/


  const generateUniqueCustomersData = (orders) => {
    // Group orders by customer
    const ordersByCustomer = orders.reduce((acc, order) => {
      if (!acc[order.email]) {
        acc[order.email] = [];
      }
      acc[order.email].push(order);
      return acc;
    }, {});

    // For each customer, find the earliest order
    const firstOrders = Object.values(ordersByCustomer).map(customerOrders => {
      return customerOrders.sort((a, b) => a.timestamp - b.timestamp)[0];
    });

    // Group first orders by date
    const firstOrdersByDate = firstOrders.reduce((acc, order) => {
      const date = new Date(order.timestamp);
      const day = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      if (!acc[day]) {
        acc[day] = [];
      }
      acc[day].push(order);
      return acc;
    }, {});

    // Generate a list of all dates between the first and last order
    const allDates = [];
    let currentDate = new Date(Object.keys(firstOrdersByDate).sort((a, b) => new Date(a) - new Date(b))[0]);
    const lastDate = new Date();
    while (currentDate <= lastDate) {
      const day = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
      allDates.push(day);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    // Merge the dates with the firstOrdersByDate data
    const allDatesWithCustomers = allDates.reduce((acc, date) => {
      acc[date] = firstOrdersByDate[date] || [];
      return acc;
    }, {});

    // Sort the dates and accumulate the number of new customers
    const sortedDates = Object.keys(allDatesWithCustomers).sort((a, b) => new Date(a) - new Date(b));
    let totalCustomers = 0;
    return sortedDates.map(date => {
      totalCustomers += allDatesWithCustomers[date].length;
      return {
        date: date,
        customers: totalCustomers
      };
    });
  };


  /*const generateFutureUniqueCustomersData = (orders) => {
    // Group orders by customer
    const ordersByCustomer = orders.reduce((acc, order) => {
      if (!acc[order.email]) {
        acc[order.email] = [];
      }
      acc[order.email].push(order);
      return acc;
    }, {});

    // For each customer, find the earliest order
    const firstOrders = Object.values(ordersByCustomer).map(customerOrders => {
      return customerOrders.sort((a, b) => a.timestamp - b.timestamp)[0];
    });

    // Group first orders by date
    const firstOrdersByDate = firstOrders.reduce((acc, order) => {
      const date = new Date(order.timestamp);
      const day = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      if (!acc[day]) {
        acc[day] = [];
      }
      acc[day].push(order);
      return acc;
    }, {});

    // Generate a list of all dates between the first and last order
    const allDates = [];
    let currentDate = new Date(Object.keys(firstOrdersByDate).sort((a, b) => new Date(a) - new Date(b))[0]);
    const lastDate = new Date();
    while (currentDate <= lastDate) {
      const day = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;
      allDates.push(day);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    // Merge the dates with the firstOrdersByDate data
    const allDatesWithCustomers = allDates.reduce((acc, date) => {
      acc[date] = firstOrdersByDate[date] || [];
      return acc;
    }, {});

    // Sort the dates and accumulate the number of new customers
    const sortedDates = Object.keys(allDatesWithCustomers).sort((a, b) => new Date(a) - new Date(b));
    let totalCustomers = 0;
    const customersData = sortedDates.map((date, index) => {
      totalCustomers += allDatesWithCustomers[date].length;
      return [index, totalCustomers];
    });

    // Perform the regression
    const result = regression.exponential(customersData);

    console.log(`Equation: y = ${result.equation[0]}e^(${result.equation[1]}x)`);
    console.log(`R^2: ${result.r2}`);

    // Generate future data
    const futureData = [];
    const futureDays = 60; // number of days in the future to predict
    for (let i = 0; i < futureDays; i++) {
      const futureDate = new Date();
      futureDate.setDate(futureDate.getDate() + i);
      const day = `${futureDate.getFullYear()}-${futureDate.getMonth() + 1}-${futureDate.getDate()}`;
      const futureCustomers = result.predict(customersData.length + i)[1];
      futureData.push({
        date: day,
        customers: Math.round(futureCustomers)
      });
    }

    return [...customersData.map(([index, customers]) => ({
      date: sortedDates[index],
      customers: customers
    })), ...futureData];
  };*/




  return (
    <>
      <div style={{width: '80%', display: 'flex', justifyContent: 'space-between' }} >
        <AverageOrderValue />
        <TotalOrderCount />
        <TotalRevenue /> {/* Add the new component here */}
      </div>
      <div style={{ width: '90%', height: 300, display: 'flex', justifyContent: 'center' }}>
        
        <div style={{ flex: 1 }}>

          <CustomLineChart
            title={"Growth of Unique Customers"}
            dataKeys={['customers']}
            data={generateUniqueCustomersData(orders)}
            colors={['#8884d8']}
            selectedInterval={selectedInterval}
            setSelectedInterval={setSelectedInterval}
          />

          {/*<CustomLineChart
            title={"Growth of Unique Customers"}
            dataKeys={['customers']}
            data={generateFutureUniqueCustomersData(orders)}
            colors={['#8884d8']}
            selectedInterval={selectedInterval}
            setSelectedInterval={setSelectedInterval}
          />*/}


          <CustomBarChart
            title={"Orders Pr. Customer"}
            dataKeys={['orders']} // use 'orders' instead of 'Number of orders'
            data={getOrdersPerCustomer()}
            colors={['#8884d8']} // choose the color you want
            width={1200}
            height={300}
          />


          <CustomBarChart
            title={"Revenue Per Customer"}
            dataKeys={['revenue']} // use 'revenue' instead of 'orders'
            data={getRevenuePerCustomer()}
            colors={['#8884d8']} // choose the color you want
            width={1200}
            height={300}
          />

          {/*<CustomHeatmap
            data={data}
            width={1200}
            height={300}
            xAccessor={xAccessor}
            yAccessor={yAccessor}
            binsAccessor={binsAccessor}
          />*/}

        </div>
      </div>
    </>
  );
};

export default CustomerInsight;

const textStyle = {
  background: 'linear-gradient(180deg, #8884d8 40%, transparent 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontSize: '5rem',
  margin: 0,
};



//  textShadow: '-2px 0 black, 0 2px black, 2px 0 black, 0 -2px black'
