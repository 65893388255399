import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import bgImg from './dashboardBG.jpg';
import logo from './logo.png';
import { TextField, Typography, Button, CircularProgress, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import loginImg from './loginImgDash.jpg';


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(process.env.REACT_APP_API_ADDRESS + '/login', {
        email: email,
        password: password,
      });

      if (response.status === 200) {
        const idToken = response.data.idToken;
        setLoading(false);
        navigate('/dashboard', { state: { idToken } });
      } else {
        setLoading(false);
        setErrorMessage('Invalid email or password.');
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage('Invalid email or password. ' + error);
    }
  };

  const style = {
    backgroundImage: `url(${bgImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: isSmallScreen ? 'cover' : '100% 100%',
    height: '100vh',
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const containerStyle = isSmallScreen ? {
    position: 'absolute',
    top: '10%',
    left: '5%',
    width: '90%',
  } : {
    position: 'absolute',
    top: '1%',
    left: '5%',
    width: '35%',
  };


  const logoStyle = {
    //position: 'relative',
    top: '0%',
    //left: '50%',
    width: "70%",
  };

  return (
    <div style={style}>
      
      <div style={containerStyle}>
        <img src={logo} alt="Logo" style={logoStyle} />
        <Typography variant="h2" style={{ fontWeight: 800, color: "#20226d", marginBottom: "0px" }} gutterBottom>Restaurant Analytics</Typography>
        <Typography variant="h2" style={{ fontWeight: 800, color: "#FF5722" }} gutterBottom>Dashboard</Typography>
        <Typography variant="h6" style={{ fontWeight: 500, color: "#20226d" }} gutterBottom>
          Welcome to our dashboard. If you are a restaurant owner or client of our service, you can login here to check the stats and metrics for your specific restaurant.
        </Typography>
        <Button 
          variant="contained" 
          style={{
            backgroundColor: '#FF5722', // dark warm orange
            color: 'white', // text color
            borderRadius: '25px', // rounded corners
            padding: '15px 30px', // more padding
            fontSize: '16px', // larger font
            margin: '20px 0', // more margin
          }}
          onClick={handleClickOpen}
        >
          Login
        </Button>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Login</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="h5" gutterBottom>Login på dit dashboard</Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  type="email"
                  label="Email"
                  variant="outlined"
                  value={email}
                  fullWidth
                  onChange={(e) => setEmail(e.target.value)}
                  margin="normal"
                />
                <TextField
                  type="password"
                  label="Password"
                  variant="outlined"
                  value={password}
                  fullWidth
                  onChange={(e) => setPassword(e.target.value)}
                  margin="normal"
                />
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    backgroundColor: '#FF5722', // dark warm orange
                    color: 'white', // text color
                    borderRadius: '25px', // rounded corners
                    padding: '15px 30px', // more padding
                    fontSize: '16px', // larger font
                    margin: '20px 0', // more margin
                  }}
                  disabled={loading}
                >
                  Login
                </Button>
                {loading && <CircularProgress />}
                {errorMessage && <p>{errorMessage}</p>}
              </form>
            </Grid>
            <Grid item xs={12} md={6}>
              <img src={loginImg} alt="Login Illustration" style={{ width: '100%' }} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {loading && <CircularProgress />}
      {errorMessage && <p>{errorMessage}</p>}
    </div>
  );
};

export default Login;
