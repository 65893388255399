import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, List, ListItem, ListItemText, Container, Typography, Box, FormControl, InputLabel, Select, MenuItem, Paper, Tooltip, ClickAwayListener } from '@mui/material';
import { HeatmapRect } from '@visx/heatmap';
import { scaleBand, scaleLinear } from '@visx/scale';

const MenuPerformance = ({ userId, idToken }) => {
  const [orders, setOrders] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState(10000);
  const [grid, setGrid] = useState([]);
  const [heatmapData, setHeatmapData] = useState([]);
  const [tooltip, setTooltip] = useState({ show: false, x: 0, y: 0, content: '' });
  const [menuItemsSales, setMenuItemsSales] = useState([]);

	const fetchOrders = async () => {
	  try {
	    const response = await axios.post(process.env.REACT_APP_API_ADDRESS + '/fetchOrders', {
	      userId,
	      selectedInterval,
	      idToken
	    });
	    setOrders(response.data);
	    console.log(response.data);

	    const heatmapData = generateHeatmapData(response.data);
	    setHeatmapData(heatmapData);
	    const menuItemsSalesData = computeMenuItemsSales(response.data);
  		setMenuItemsSales(menuItemsSalesData);
	  } catch (error) {
	    console.error('Error fetching orders from server:', error);

	    if (error.response && error.response.status === 500 && error.message.includes("expired")) {
	      window.location.href = '/login';
	    }
	  }
	};

  useEffect(() => {
    fetchOrders();
  }, [selectedInterval]);

  useEffect(() => {
    if (orders.length > 0) {
      const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const hoursInDay = 24;

      let newGrid = new Array(daysOfWeek.length).fill(null).map(() => new Array(hoursInDay).fill(0));

      orders.forEach(order => {
        const date = new Date(order.timestamp);
        const dayOfWeek = date.getDay();
        const hourOfDay = date.getHours();
        const revenue = parseFloat(order.totalPrice);

        newGrid[dayOfWeek][hourOfDay] += revenue;
      });

      setGrid(newGrid);
    }
  }, [orders]);

  const handleIntervalChange = (e) => {
    setSelectedInterval(e.target.value * 24 * 60 * 60 * 1000);
  };

  // Heatmap settings
  const width = 1000;
  const height = 800;
  const margin = { top: 80, right: 30, bottom: 30, left: 80 };
  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const hoursInDay = Array.from({ length: 24 }, (_, i) => i);

  const xScale = scaleBand({
    domain: daysOfWeek,
    range: [margin.left, width - margin.right],
    padding: 0.1,
  });

  const yScale = scaleBand({
    domain: hoursInDay,
    range: [margin.top, height - margin.bottom],
    padding: 0.1,
  });

	const colorScale = scaleLinear({
	  domain: [0, Math.max(...grid.map(day => Math.max(...day)))],
	  range: ['#ffffff', '#ff5a39'], // Change the default color to white
	});


	const generateHeatmapData = (orders) => {
	  const heatmapData = Array(7)
	    .fill(0)
	    .map(() => Array(24).fill(0));

	  orders.forEach((order) => {
      const orderDate = new Date(order.timestamp);
      const dayOfWeek = orderDate.getDay();
      const hourOfDay = orderDate.getHours();
      const revenue = Number(order.totalPrice);

      heatmapData[dayOfWeek][hourOfDay] += revenue;
	  });

	  console.log(heatmapData);

	  return heatmapData;
	};


	const handleMouseEnter = (e, day, hour, bin) => {
    setTooltip({
      show: true,
      x: e.clientX,
      y: e.clientY,
      content: `Day: ${day}\nHour: ${hour}:00\nSales: ${bin.toFixed(2)}`,
    });
  };

  const handleMouseLeave = () => {
	  setTimeout(() => {
	    setTooltip({ show: false, x: 0, y: 0, content: '' });
	  }, 300);
	};


	const computeMenuItemsSales = (orders) => {
	  const salesData = {};

	  orders.forEach((order) => {
	    order.orderData.forEach((item) => {
	      if (salesData[item.title]) {
	        salesData[item.title].count += 1; // Assuming each item has a quantity of 1
	        salesData[item.title].amount += item.price;
	      } else {
	        salesData[item.title] = {
	          count: 1,
	          amount: item.price,
	        };
	      }
	    });
	  });

	  // Convert the sales data object to an array and sort it by the sales count in descending order
	  const sortedSalesData = Object.entries(salesData)
	    .map(([name, { count, amount }]) => ({ name, count, amount }))
	    .sort((a, b) => b.count - a.count);

	  console.log(sortedSalesData);

	  return sortedSalesData;
	};



	return (
	  <div>
	    <Box>
	      
	    </Box>

	    <Box mb={4}>
	      <FormControl variant="outlined" >
	        <InputLabel id="interval-label">Interval</InputLabel>
	        <Select
	          labelId="interval-label"
	          value={selectedInterval}
	          onChange={(e) => setSelectedInterval(e.target.value)}
	        >
	          <MenuItem value="7">7 days</MenuItem>
	          <MenuItem value="14">14 days</MenuItem>
	          <MenuItem value="30">30 days</MenuItem>
	          <MenuItem value="60">60 days</MenuItem>
	          <MenuItem value="120">120 days</MenuItem>
	          <MenuItem value="240">240 days</MenuItem>
	          <MenuItem value="10000">All time</MenuItem>
	        </Select>
	      </FormControl>
	    </Box>

	    <Paper elevation={3}>
	    	<Grid container spacing={2}>
		    	<Grid item xs={12} md={8}>
		    		<Typography variant="h4" align="left" gutterBottom>
			        Revenue Heatmap by Day and Hour
			      </Typography>
			      <svg width={width} height={height} style={{paddingLeft:"30px"}} >
			        <svg width={width} height={height} >
						    {heatmapData.map((bins, i) =>
						      bins.map((bin, j) => (
						      	<Tooltip
			                key={`heatmap-tooltip-${i}-${j}`}
			                title={bin.toFixed(2) > 0 ? `Day: ${daysOfWeek[i]}\nHour: ${hoursInDay[j]}:00\nSales: ${bin.toFixed(2)} kr` : ''}
			                enterDelay={300}
			                leaveDelay={0}
			                interactive
			                disableFocusListener
			                disableTouchListener
			                sx={{
											    fontSize: '16px', // Increase font size
											    fontWeight: 'bold', // Make the font bold
											    backgroundColor: 'rgba(255, 255, 255, 0.9)', // Add a semi-transparent white background
											    border: '1px solid #ccc', // Add a border
											    borderRadius: '4px', // Add border radius
											    padding: '8px', // Add padding
											    '.MuiTooltip-tooltip': { // Add this to target the tooltip content specifically
											      fontSize: '16px',
											      fontWeight: 'bold',
											      backgroundColor: 'rgba(255, 255, 255, 0.9)',
											      border: '1px solid #ccc',
											      borderRadius: '4px',
											      padding: '8px',
											    },
											  }}
			              >
							        <g key={`heatmap-group-${i}-${j}`} 
							        onMouseEnter={(e) => handleMouseEnter(e, daysOfWeek[i], hoursInDay[j], bin)}
			                onMouseLeave={handleMouseLeave} >
							          <Box
							            component="rect"
							            key={`heatmap-rect-${i}-${j}`}
							            x={xScale(daysOfWeek[i])}
							            y={yScale(hoursInDay[j])}
							            width={xScale.bandwidth()}
							            height={yScale.bandwidth()}
							            fill={colorScale(bin)}
							            sx={{
							              transition: '0.3s',
							              '&:hover': {
							                fill: '#ff5a39',
							                cursor: 'pointer',
							              },
							            }}
							          />
							          <Typography
							            x={xScale(daysOfWeek[i]) + xScale.bandwidth() / 2}
							            y={yScale(hoursInDay[j]) + yScale.bandwidth() / 2}
							            textAnchor="middle"
							            fontSize={15}
							            fontWeight="bold"
							            dy=".35em"
							            fill="#333"
							            component="text"
							          >
							          	{bin.toFixed(2) > 0 ? bin.toFixed(2) + " kr" : null}
							          </Typography>
							        </g>
							      </Tooltip>
						      )),
						    )}
						  </svg>


				      {/* Add text labels for days of the week */}
				      {daysOfWeek.map((day, i) => (
				        <Typography
				          key={`day-label-${i}`}
				          x={xScale(day) + xScale.bandwidth() / 2}
				          y={margin.top - 10}
				          textAnchor="middle"
				          fontSize={16} // Increase font size
				          fontWeight="bold"
				          component="text"
				        >
				          {day}
				        </Typography>
				      ))}

				      {/* Add text labels for hours of the day */}
				      {hoursInDay.map((hour, i) => (
				        <Typography
				          key={`hour-label-${i}`}
				          x={margin.left - 15} // Adjust the x position of the hour labels
				          y={yScale(hour) + yScale.bandwidth() / 2}
				          textAnchor="end"
				          fontSize={16} // Increase font size
				          dy=".35em"
				          component="text"
				        >
				          {hour}:00
				        </Typography>
				      ))}
				    </svg>
				  </Grid>

			    <Grid item xs={12} md={4}>
					  <Typography variant="h4" align="left" gutterBottom>
			      	Sales by Menu Item 
			      </Typography>
					  <List>
					    {menuItemsSales.map((menuItem, index) => (
					      <ListItem key={index}>
					        <ListItemText
					          primary={`${index + 1}. ${menuItem.name}`}
					          secondary={`Antal Solgt: ${menuItem.count} | Total Salg: ${menuItem.amount} kr`}
					        />
					      </ListItem>
					    ))}
					  </List>
					</Grid>

				</Grid>

		  </Paper>
	  </div>
	);

};

export default MenuPerformance;

const styles = {
  heatmapLabel: {
    fill: "#333",
    fontWeight: "bold",
  },
  axisLabel: {
    fill: "#555",
  },
};
