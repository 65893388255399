import React, { useEffect, useState } from 'react';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import CustomLineChart from './CustomLineChart';
//import { getDatabase, ref, onValue } from 'firebase/database';
import axios from 'axios';


const SalesAndOrders = ({ userId, idToken }) => {
  const [orders, setOrders] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState(10000); // Default interval, e.g., 10000 days

  const fetchOrders = async () => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_ADDRESS + '/fetchOrders', {
        userId,
        selectedInterval,
        idToken
      });
      setOrders(response.data);
    } catch (error) {
      console.error('Error fetching orders from server:', error);

      if (error.response && error.response.status === 500 && error.message.includes("expired")) {
        // Redirect to login page if the token has expired
        window.location.href = '/login';
      }
    
    }
  };


  useEffect(() => {
    if (userId && selectedInterval) {
      fetchOrders();
    }
  }, [userId, selectedInterval]);





  const getChartData = () => {
    const currentDate = new Date();
    const startDate = orders.length > 0 ? new Date(orders[0].timestamp) : currentDate;
    
    const fillEmptyDates = (start, end) => {
      const dateArray = [];
      let currentDate = new Date(start);

      while (currentDate <= end) {
        const formattedDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}`;
        dateArray.push({ date: formattedDate, orders: 0 });
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return dateArray;
    };

    const emptyDates = fillEmptyDates(startDate, currentDate);

    const groupedByDate = orders.reduce((acc, order) => {
      const date = new Date(order.timestamp);
      const formattedDate = `${date.getMonth() + 1}/${date.getDate()}`;

      if (!acc[formattedDate]) {
        acc[formattedDate] = { date: formattedDate, orders: 0 };
      }

      acc[formattedDate].orders += 1; // Increment the count of orders for this date

      return acc;
    }, {});

    const mergedData = emptyDates.map((item) => {
      return groupedByDate[item.date] ? groupedByDate[item.date] : item;
    });

    console.log(mergedData);
    return mergedData;
  };




  const getRevenueChartData = () => {
    const currentDate = new Date();
    const startDate = orders.length > 0 ? new Date(orders[0].timestamp) : currentDate;

    const fillEmptyDates = (start, end) => {
      const dateArray = [];
      let currentDate = new Date(start);

      while (currentDate <= end) {
        const formattedDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}`;
        dateArray.push({ date: formattedDate, indkomst: 0 });
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return dateArray;
    };

    const emptyDates = fillEmptyDates(startDate, currentDate);

    const groupedByDate = orders.reduce((acc, order) => {
      const date = new Date(order.timestamp);
      const formattedDate = `${date.getMonth() + 1}/${date.getDate()}`;

      if (!acc[formattedDate]) {
        acc[formattedDate] = { date: formattedDate, indkomst: 0 };
      }

      // Updated line
      acc[formattedDate].indkomst = parseFloat((acc[formattedDate].indkomst + parseFloat(order.totalPrice)).toFixed(2));

      return acc;
    }, {});

    const mergedData = emptyDates.map((item) => {
      return groupedByDate[item.date] ? groupedByDate[item.date] : item;
    });

    console.log(mergedData);
    return mergedData;
  };



  const getAverageOrderValue = () => {
    if (orders.length === 0) {
      return 0;
    }

    const totalRevenue = orders.reduce((total, order) => total + parseFloat(order.totalPrice), 0);
    const averageOrderValue = totalRevenue / orders.length;

    return Math.round(averageOrderValue);
  };


  const AverageOrderValue = () => {
    const averageOrderValue = getAverageOrderValue();

    return (
      <div style={{ textAlign: 'center',width:"12%" }}>
        <h5 style={{margin:0}} >Gennemsnitlige Ordre Pris</h5>
        <h1 style={textStyle} >{averageOrderValue}</h1>
      </div>
    );
  };


  const getTotalOrderCount = () => {
    return orders.length;
  };

  const TotalOrderCount = () => {
    const totalOrderCount = getTotalOrderCount();

    return (
      <div style={{ textAlign: 'center', width: '12%' }}>
        <h5 style={{ margin: 0 }}>Totale mængde af ordre</h5>
        <h1 style={textStyle}>{totalOrderCount}</h1>
      </div>
    );
  };


  const getTotalRevenue = () => {
    return orders.reduce((total, order) => total + parseFloat(order.totalPrice), 0).toFixed(2);
  };

  const TotalRevenue = () => {
    const totalRevenue = getTotalRevenue();

    return (
      <div style={{ textAlign: 'center', width: '35%' }}>
        <h5 style={{ margin: 0 }}>Totale indkomst i denne tidsperiode</h5>
        <h1 style={textStyle}>{totalRevenue}</h1>
      </div>
    );
  };


  return (
    <>
      <div style={{width: '80%', display: 'flex', justifyContent: 'space-between' }} >
        <AverageOrderValue />
        <TotalOrderCount />
        <TotalRevenue /> {/* Add the new component here */}
      </div>
      <div style={{ width: '90%', height: 300, display: 'flex', justifyContent: 'center' }}>
        
        <div style={{ flex: 1 }}>
          <CustomLineChart
            title={"Ordre"}
            dataKeys={['orders']}
            data={getChartData()}
            selectedInterval={selectedInterval}
            setSelectedInterval={setSelectedInterval}
          />

          <CustomLineChart
            title={"Indkomst"}
            dataKeys={['indkomst']}
            data={getRevenueChartData()}
            selectedInterval={selectedInterval}
            setSelectedInterval={setSelectedInterval}
          />
        </div>
      </div>
    </>
  );
};

export default SalesAndOrders;

const textStyle = {
  background: 'linear-gradient(180deg, #8884d8 40%, transparent 100%)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontSize: '5rem',
  margin: 0,
};



//  textShadow: '-2px 0 black, 0 2px black, 2px 0 black, 0 -2px black'
