import React, { useEffect, useState } from 'react';
import { ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Area, defs, linearGradient, stop } from 'recharts';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';

const CustomLineChart = ({
  title,
  data,
  dataKeys = ['sales'],
  colors = ['#8884d8'],
  selectedInterval,
  setSelectedInterval,
}) => {
  // Find the maximum sales value in the data for Y-axis domain
  const maxValue = data.length > 0
    ? Math.max(...data.flatMap((item) => dataKeys.map((key) => item[key])).filter((value) => !isNaN(value)))
    : 0;
  useEffect(() => {
    console.log('The max value is: ' + maxValue);
    console.log(data);
  }, [maxValue]);

  return (
    <>
      <h2>{title}</h2>
      <FormControl>
        <InputLabel id="interval-label">Interval</InputLabel>
        <Select
          labelId="interval-label"
          value={selectedInterval}
          onChange={(e) => setSelectedInterval(e.target.value)}
        >
          <MenuItem value="7">7 days</MenuItem>
          <MenuItem value="14">14 days</MenuItem>
          <MenuItem value="30">30 days</MenuItem>
          <MenuItem value="60">60 days</MenuItem>
          <MenuItem value="120">120 days</MenuItem>
          <MenuItem value="240">240 days</MenuItem>
          <MenuItem value="10000">All time</MenuItem>
        </Select>
      </FormControl>

      <ResponsiveContainer width="100%" aspect={4.0 / 1.0}>
        <ComposedChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <defs>
            <linearGradient id={`color${colors[0]}`} x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={colors[0]} stopOpacity={0.8}></stop>
              <stop offset="95%" stopColor={colors[0]} stopOpacity={0.05}></stop>
            </linearGradient>
            <filter id="shadow" height="200%">
              <feGaussianBlur in="SourceAlpha" stdDeviation="7" result="blur" />
              <feOffset in="blur" dx="0" dy="7" result="offsetBlur" />
              <feFlood floodColor="#109065" floodOpacity="0.5" result="offsetColor" />
              <feComposite
                in="offsetColor"
                in2="offsetBlur"
                operator="in"
                result="offsetBlur"
              />
              <feMerge>
                <feMergeNode />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis   domain={[0, maxValue * 1.1]} tickFormatter={(tick) => Math.round(tick)} />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey={dataKeys[0]}
            stroke={colors[0]}
            strokeWidth={3} // Set the thickness of the line
            activeDot={{ r: 8 }}
            filter="url(#shadow)" // Apply the shadow effect
          />
          <Area
            type="monotone"
            dataKey={dataKeys[0]}
            fillOpacity={1}
            fill={`url(#color${colors[0]})`}
            stroke={colors[0]}
            strokeWidth={0}
            filter="url(#shadow)" // Apply the shadow effect
            legendType="none"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};

export default CustomLineChart;
