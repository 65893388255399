import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CustomLineChart from './CustomLineChart';
import CustomBarChart from "./CustomBarChart";

const DigitalPerformance = ({ userId, idToken }) => {
  const [data, setData] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState('7');

  const getDateRange = (interval) => {
    const currentDate = new Date();
    const startDate = new Date(currentDate.getTime() - interval * 24 * 60 * 60 * 1000);
    const startDateString = `${startDate.getFullYear()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getDate().toString().padStart(2, '0')}`;
    const endDateString = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')}`;

    return {
      startDate: startDateString,
      endDate: endDateString,
    };
  };

  useEffect(() => {



    const fetchData = async () => {
      const { startDate, endDate } = getDateRange(selectedInterval);

      try {
        const response = await axios.get(process.env.REACT_APP_API_ADDRESS + `/analytics/users-over-time?userId=${userId}&idToken=${idToken}&interval=${selectedInterval}&startDate=${startDate}&endDate=${endDate}`);
        console.log(response);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [selectedInterval]);

  const formatDate = (dateString) => {
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    return `${year}-${month}-${day}`;
  };

  const mapData = (rows) => {
    return rows
      .map((item) => ({
        date: formatDate(item.dimensionValues[0].value),
        value: parseInt(item.metricValues[0].value),
      }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  };

  const activeUsersData = mapData(data.activeUsers?.rows || []);
  const screenPageViewsData = mapData(data.screenPageViews?.rows || []);


  const mapTrafficSourcesData = (rows) => {
    return rows.map((item) => ({
      name: item.dimensionValues[0].value,
      source: item.dimensionValues[1].value,
      medium: item.dimensionValues[2].value,
      sessions: parseInt(item.metricValues[0].value),
    }));
  };

  const trafficSourcesData = mapTrafficSourcesData(data.trafficSources?.rows || []);


  return (
    <div>
      <CustomLineChart
        title="Total Users"
        data={activeUsersData}
        dataKeys={['value']}
        colors={['#8884d8']}
        selectedInterval={selectedInterval}
        setSelectedInterval={setSelectedInterval}
      />
      <CustomLineChart
        title="Screen Page Views"
        data={screenPageViewsData}
        dataKeys={['value']}
        colors={['#82ca9d']}
        selectedInterval={selectedInterval}
        setSelectedInterval={setSelectedInterval}
      />
      <CustomBarChart
        title="Traffic Sources"
        data={trafficSourcesData}
        dataKeys={['sessions']}
        colors={['#FF8042']}
      />
    </div>
  );
};

export default DigitalPerformance;
